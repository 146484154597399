export default {
  methods: {
    showSuccessToast (message) {
      this.$bvToast.toast(message, {
        title: this.$t('TOASTS.title_success'),
        variant: 'success',
        solid: true
      })
    },
    showErrorToast (message) {
      this.$bvToast.toast(message, {
        title: this.$t('TOASTS.title_error'),
        variant: 'danger',
        solid: true
      })
    }
  }
}
