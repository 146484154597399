<template>
  <div>
    <b-row class="main-container py-5 px-md-5">
      <b-col xs="12" lg="4" xl="3" class="mb-3">
        <Card no-shadow>
          <template v-slot:content>

            <div class="profile-header">
              <span class="avatar-wrapper" @click="showProfilPicChangeModal">
                <b-avatar rounded="lg" :src="profilePic" size="5rem" class="mr-3" ></b-avatar>
                <div class="middle">
                  <font-awesome-icon :icon="['fa', 'camera']" style="color: black; font-size: 2rem;" />
                </div>
              </span>
              <div style="display: inline-block; width: 60%; vertical-align: middle;">
                <span class="font-weight-semibold">{{ user.name }} {{ user.surname }}</span><br>
                <span style="font-size: 0.8rem;">{{ user.email }}</span><br>
                <span style="font-size: 0.8rem;">{{ $t('PROFILE.last_login') }}: {{ new Date().toLocaleDateString() }}</span>
              </div>
            </div>

            <div class="profile-menu">

              <router-link :to="{ name: section.route }" class="section" v-for="(section) in sections" v-bind:key="section.id">
                <b-icon :icon="section.icon" class="mr-2"  />{{ $t(section.name) }}
              </router-link>

            </div>
          </template>
        </Card>
      </b-col>

      <b-col xs="12" lg="8" xl="9">
        <Card no-shadow>
          <template v-slot:content>
            <transition name="fade-short" mode="out-in">
              <router-view :key="$route.fullPath" />
            </transition>
          </template>
        </Card>
      </b-col>
    </b-row>

    <b-modal
      ref="modal"
      :title="$t('PROFILE.upload_profile_pic')"
      :ok-title="$t('ACTIONS.accept')"
      :cancel-title="$t('ACTIONS.cancel')"
      @ok="submitPhoto"
    >
    <div class="text-center" v-if="!isAuthLoading">
      <b-avatar rounded="lg" :src="newProfilePicPreview || profilePic" size="5rem" ></b-avatar><br>
    </div>
      <input type="file" accept="image/*" @change="savePic" v-if="!isAuthLoading">
      <div class="text-center" v-if="isAuthLoading">
        <b-spinner class="align-middle mr-2" ></b-spinner>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ToastsMixin from '@/services/mixins/toasts.mixins'

export default {
  name: 'ProfileLayout',
  data () {
    return {
      sections: [
        {
          name: 'PROFILE.my_formation',
          route: 'profileHome',
          icon: 'percent'
        },
        {
          name: 'PROFILE.info',
          route: 'profileInfo',
          icon: 'person-fill'
        },
        // {
        //   name: 'PROFILE.reports',
        //   route: 'profileReports',
        //   icon: 'file-earmark-arrow-down-fill'
        // },
        // {
        //   name: 'Acceso VPN',
        //   route: 'profileVpn',
        //   icon: 'diagram3-fill'
        // },
        {
          name: 'PROFILE.certifications',
          route: 'profileCertifications',
          icon: 'award-fill'
        }
      ],
      newProfilePic: null
    }
  },
  mixins: [ToastsMixin],
  methods: {
    ...mapActions({
      updateUserProfilePic: 'auth/updateUserProfilePic'
    }),
    showProfilPicChangeModal () {
      this.$refs.modal.show()
    },
    savePic (ev) {
      this.newProfilePic = ev.target.files[0]
    },
    submitPhoto (ev) {
      ev.preventDefault()
      return this.updateUserProfilePic(this.newProfilePic)
        .then(_ => {
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs.modal.hide()
          })
        })
        .catch(_ => {
          this.showErrorToast(this.$t('PROFILE.ERRORS.error_uploading_pic'))
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/currentUser',
      dummyProfilePic: 'staticInfo/getDummyProfilePic',
      isAuthLoading: 'auth/isLoading',
      isLoading: 'user/isLoading'
    }),
    newProfilePicPreview () { return this.newProfilePic !== null ? URL.createObjectURL(this.newProfilePic) : '' },
    hasProfilePic () { return typeof this.user.profile_pic !== 'undefined' && this.user.profile_pic !== '' },
    profilePic () {
      return this.hasProfilePic ? this.user.profile_pic : this.dummyProfilePic
    }
  }
}
</script>

<style lang="scss" scoped>

.avatar-wrapper {
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.3;

    .middle {
      opacity: 1;
    }
  }

  .middle {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-75%, -50%);
    -ms-transform: translate(-75%, -50%);
    text-align: center;
  }
}
.card.report {
  img {
    max-width: 100%;
  }
}

.profile-menu {
  margin-top: 32px;
  padding: 8px ;

  & > * {
    padding: 8px 12px;
    border-radius: 8px;
    cursor: pointer;
    display: block !important;
    text-decoration: none;

    &.router-link-exact-active,  &.router-link-exact-active:hover {
      color: var(--primary-inverse);
      background-color: var(--primary);
    }

    &:hover {
      color: var(--secondary-inverse);
      background: var(--secondary);
    }
  }
}

</style>
